@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 10;
    src: url("./fonts/dana-fanum-thin.eot");
    src: url("./fonts/dana-fanum-thin.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-thin.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 200;
    src: url("./fonts/dana-fanum-extralight.eot");
    src: url("./fonts/dana-fanum-extralight.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-extralight.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/dana-fanum-light.eot");
    src: url("./fonts/dana-fanum-light.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-light.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/dana-fanum-medium.eot");
    src: url("./fonts/dana-fanum-medium.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-medium.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 600;
    src: url("./fonts/dana-fanum-demibold.eot");
    src: url("./fonts/dana-fanum-demibold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-demibold.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 750;
    src: url("./fonts/dana-fanum-ultrabold.eot");
    src: url("./fonts/dana-fanum-ultrabold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-ultrabold.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 800;
    src: url("./fonts/dana-fanum-extrabold.eot");
    src: url("./fonts/dana-fanum-extrabold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-extrabold.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 900;
    src: url("./fonts/dana-fanum-black.eot");
    src: url("./fonts/dana-fanum-black.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-black.woff2") format("woff2");
}

@font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/dana-fanum-bold.eot");
    src: url("./fonts/dana-fanum-bold.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-bold.woff2") format("woff2");
}

@font-face {
    font-family: dana !important;
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/dana-fanum-regular.eot");
    src: url("./fonts/dana-fanum-regular.eot?#iefix") format("embedded-opentype"),
        url("./fonts/dana-fanum-regular.woff2") format("woff2");
}

:root[dir=rtl] {
    @for $i from 1 through 10 {
        .ms-#{$i} {
            margin-right: $i * 0.25rem !important;
        }
    }

    @for $i from 1 through 10 {
        .me-#{$i} {
            margin-left: $i * 0.25rem !important;
        }
    }

    @for $i from 1 through 10 {
        .ps-#{$i} {
            padding-right: $i * 0.25rem !important;
        }
    }

    @for $i from 1 through 10 {
        .pe-#{$i} {
            padding-left: $i * 0.25rem !important;
        }
    }

    html,
    body {

        font-family: "dana" !important;
    }

    .end-0 {
        right: unset !important;
        left: 0 !important;
    }

    .accordion-button::after {
        margin-right: auto !important;
        margin-left: unset !important;
    }

    .workflow_name {
        right: 0;
        left: unset !important;
    }

    .node_item {
        .btn-group {
            direction: ltr !important;
        }

        .accordion {
            overflow-x: hidden !important
        }

        .accordion-button {
            direction: ltr !important;
        }
    }

    .work_drawer {
        left: 0 !important;
        right: unset !important;
        border-top-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: var(--bs-card-border-radius) !important;
        border-bottom-right-radius: var(--bs-card-border-radius) !important;

        .work_drawer_button {
            padding: 0 !important;
            rotate: 180deg !important;
            right: -37px !important;
            transform: translateY(-100%) !important;
        }

        .work_drawer_button_config {
            padding: 0 !important;
            rotate: 180deg !important;
            right: -37px !important;
            transform: translateY(+300%) !important;

            .btn {
                rotate: -180deg !important;

            }
        }

        .svg_provider {
            rotate: 180deg !important;
        }
    }

    .work_drawer[show=false] {
        .svg_provider {
            rotate: 0deg !important;
        }
    }

    .costume_rtl {
        right: 0;

        #kt_app_sidebar_logo {
            div {
                right: 100% !important;
                transform: translate(-50%, +50%) !important;
                rotate: 180deg;
            }
        }
    }

    .drawer.drawer-start {
        right: 0;
        transform: translateX(100%);
    }

    .drawer.drawer-end {
        right: unset;
        left: 0 !important;
        transform: translateX(-100%);
    }

    .drawer.drawer-on {
        transform: none;
        box-shadow: var(--bs-drawer-box-shadow);
        transition: transform 0.3s ease-in-out !important;
    }

    @media (min-width: 992px) {
        [data-kt-app-sidebar-fixed=true] .app-wrapper {
            margin-left: unset;
            margin-right: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
        }

    }

    @media (min-width: 992px) {

        [data-kt-app-header-fixed=true][data-kt-app-sidebar-fixed=true][data-kt-app-sidebar-push-header=true] .app-header,
        [data-kt-app-header-fixed=true][data-kt-app-sidebar-sticky=on][data-kt-app-sidebar-push-header=true] .app-header {
            right: calc(var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) + var(--bs-app-sidebar-gap-end, 0px));
        }
    }

    #kt_app_header {
        left: 0 !important;
    }

    #chartDiv {
        direction: ltr;
    }

    .menu-icon {
        margin-left: 0.5rem;
    }

    .Date {
        width: fit-content !important;
        direction: ltr !important;
        text-transform: uppercase;

    }

    .image_change_theme {
        transform: rotateY(180deg);
    }

    .image_change_theme2 {
        transform: rotateY(180deg);
    }

    .live {
        span {
            text-wrap: nowrap;
        }
    }

    .me-auto {
        margin: unset !important;
        margin-left: auto !important;
    }

    .ms-auto {
        margin: unset !important;
        margin-right: auto !important;
    }

    .pe-auto {
        padding-left: auto !important;
    }

    .ps-auto {
        padding-right: auto !important;
    }

    .scoreLabel {

        transform: rotate(315deg);
        left: -41px !important;
        right: unset;
        top: -28px;
        font-size: 24px;
        padding: 0px 42px;
        font-weight: bolder;

        span {
            transform: rotate(-315deg);
        }
    }

    .ai_btn,
    .scrolltop {
        right: unset !important;
        left: 10px !important;
        transform: rotateY(-180deg) !important;
    }

    .number_progress {
        left: -10px !important;
        margin: 0 !important;
    }

    .ssl_grade {
        rotate: 180deg;
        transform: rotateX(180deg);

        span {
            width: 24px !important;
            top: 17px !important;
            right: 3px !important;
            font-weight: bolder !important;
            font-size: large !important;
            transform: rotateY(176deg) rotate(34deg) !important;
            text-align: center !important;
        }
    }

    .vul_target {
        justify-content: center;
    }

    .form-check-input-reverse {
        float: right !important;
        margin-left: 10px !important;
    }

    .menu-sub-indention .menu-sub:not([data-popper-placement]) {
        margin-left: 0rem !important;
        margin-right: 1rem !important;
    }

    .border-start {
        border-left: none !important;
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    }
}