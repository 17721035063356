.react-flow__edge-path {
  stroke-width: 2px !important;
}
.large_item_icon {
  background-image: url('../../../../../public/6.png');
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.large_item {
  padding: 0 !important;
  width: 60px ;
  height: 60px ;
  background-color: transparent !important;
  border: none !important;

  .large_item_icon {
    background-image: url('../../../../../public/6.png');
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }

  .label {
    color: var(--bs-gray-900) !important;
    position: absolute !important;
    width: 100px ;
    min-width: 100px !important;
    font-size: 12px ;
  }

  .connection_limit {
    background: #ff0000 !important;
    cursor: not-allowed !important;
  }
}

.large_item:hover {
  span {
    display: inline !important;
  }

  .connection_limit {
    background: #ff0000 !important;
    cursor: not-allowed !important;

    .limit {
      display: inline !important;
    }
  }
}

.react-flow__attribution {
  visibility: hidden !important;
}

.work_drawer {
  width: 320px;
  transition: all 0.3s linear !important;
  height: calc(100% - 75px) !important;
  position: fixed !important;
  top: 75px !important;
  bottom: 0 !important;
  right: 0 !important;
  // z-index: 10 ;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  .node_item {
    overflow: auto !important;
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px !important;
    padding: 10px 10px !important;
  }

  .work_drawer_button {
    position: absolute !important;
    width: 39px !important;
    height: 58px !important;
    border-top-left-radius: 150px !important;
    border-bottom-left-radius: 150px !important;
    left: -39px !important;
    top: 50% !important;
    transform: translateY(-300%) !important;
    z-index: 1000;
  }

  .work_drawer_button_config {
    position: absolute !important;
    width: 39px !important;
    height: 58px !important;
    border-top-left-radius: 150px !important;
    border-bottom-left-radius: 150px !important;
    left: -39px !important;
    top: 50% !important;
    transform: translateY(+100%) !important;
  }

  .svg_provider {
    transition: all 0.3s linear !important;
    rotate: 0 !important;
  }
}

.work_drawer[show=false] {
  width: 0 !important;
  transition: all 0.3s linear !important;

  .svg_provider {
    transition: all 0.3s linear !important;
    rotate: 180deg !important;
  }
}


////////////////////
.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/////////////////
.validationflow .react-flow__node-customnode {
  background: transparent;
  border: 1px solid #ddd;
}

.react-flow__handle {
  background: transparent;
}

.validationflow .react-flow__node-custominput {
  background: transparent;
}

.limit {
  display: none !important;
  color: red;
  text-wrap: nowrap;
}

.connectingto {
  background: #ff0000 !important;
  cursor: not-allowed !important;

}

// .connectingto:hover{

// }
.valid {
  background: #55dd99 !important;
  cursor: cell !important;
}

/////////////////////
.workflow_node_item {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: 0.5px solid gray !important;
  cursor: grab
}

.workflow_node_item:hover {
  border-bottom: 0.5px solid rgb(255, 255, 255) !important;
  background-color: #7f7f7f2f !important;
}

.workflow_toolkit {
  // min-width: 100px !important;
  min-height: 30px !important;
  display: flex !important;
  position: absolute !important;
  // top: -25px;
  left: 50% !important;
  transform: translateX(-50%);
  gap: 10px !important;
  z-index: 8 !important;

  .tooltip_tt {
    cursor: pointer !important;
    padding: 5px !important;
    border-radius: 4px !important;
    border: 1px solid gray !important;
    transition: all 3ms ease-in-out !important;
    background-color: #80808046;
    min-width: 32.6px;
  }

  .tooltip_tt:hover {
    scale: 1.1 !important;
    border: 1px solid rgb(190, 190, 190) !important;
  }
}


.work-drawer {
  position: fixed;
  top: 0;
  right: -400px; // Initial position (hidden)
  height: 100vh;
  transition: right 0.3s ease-in-out;

  &.show {
    right: 0;
  }

  .drawer-content {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
  }

  .drawer-toggle {
    position: absolute;
    left: -32px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 4px 0 0 4px;
    padding: 0.5rem;
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--bs-light);
    }
  }

  .config-details {
    .config-item {
      transition: background-color 0.2s;
      border: 1px solid rgba(0, 0, 0, 0.05);

      &:hover {
        background-color: var(--bs-light) !important;
      }
    }
  }
}

.work_drawer {


  .drawer-content {
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }
  }

  .drawer-toggle {
    position: absolute;
    right: -32px;
    top: 50%;
    transform: translateY(-50%);
    background: var(--bs-secondary);
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 0.5rem;
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  }

  // Custom Radio Buttons
  .btn-check:checked+.btn-outline-primary {
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
    color: white;
  }

  // Search Input
  .input-group {
    .input-group-text {
      border-color: var(--bs-border-color);
    }

    .form-control {
      border-color: var(--bs-border-color);

      &:focus {
        box-shadow: none;
        border-color: var(--bs-primary);

        &+.input-group-text {
          border-color: var(--bs-primary);
        }
      }
    }
  }

  // Accordion Customization
  .custom-accordion {
    .accordion-item {
      background-color: var(--bs-body-bg);
      transition: box-shadow 0.2s;

      &:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }

      .accordion-button {
        padding: 1rem;

        &:not(.collapsed) {
          color: var(--bs-primary);
          box-shadow: none;
        }

        &:focus {
          box-shadow: none;
          border-color: var(--bs-primary);
        }
      }
    }
  }

  // Node Grid
  .node-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 1rem;
  }

  // Node Items
  .workflow-node {
    user-select: none;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    cursor: grab;
    background-color: var(--bs-light);
    transition: transform 0.2s, background-color 0.2s;
    gap: 4px;

    &:hover {
      background-color: var(--bs-app-sidebar-light-scrollbar-color-hover);
      border: 1px solid var(--bs-primary);
      // transform: translateY(-2px);
    }

    &:active {
      cursor: grabbing;
      border: 1px solid var(--bs-primary);
    }

    .node-icon {
      width: 40px;
      height: 40px;


      .large-item {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: var(--bs-gray-400);
        background-position: center !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
        background-image: url('../../../../../public/6.png');
      }
    }

    .info-icon {
      width: 1.5rem !important;
      height: 1.5rem !important;
    }

    .node-label {
      margin: 0;
      text-align: center;
      font-size: 15px;
      color: var(--bs-gray-700);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.react-flow__node-default.selected,
.react-flow__node-group.selected,
.react-flow__node-input.selected,
.react-flow__node-output.selected {
  box-shadow: none !important;
  border: unset !important;
}

.react-flow__node-default.selectable:hover,
.react-flow__node-group.selectable:hover,
.react-flow__node-input.selectable:hover,
.react-flow__node-output.selectable:hover {
  box-shadow: none !important;
  border: unset !important;
}

.react-flow__node-default.selected,
.react-flow__node-group.selected,
.react-flow__node-input.selected,
.react-flow__node-output.selected {
  .large_item {

    .large_item_icon {

      filter: contrast(0.1);
    }



  }

  box-shadow: none;
  border: unset !important;
}

:root[data-bs-theme=light] {

  .react-flow__node-default.selected,
  .react-flow__node-group.selected,
  .react-flow__node-input.selected,
  .react-flow__node-output.selected {
    .large_item {
      .large_item_icon {

        filter: contrast(0.1);
      }



    }

    box-shadow: none;
    border: unset !important;
  }

}

.react-flow__node,
.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  box-shadow: none !important;
  background-color: transparent !important;
  width: fit-content !important;
  border: unset !important;
  padding: 0 !important;
}

.invert {
  filter: invert(1);
}

.core-styles-module_show__Nt9eE {
  opacity: 1 !important;
}

//////////////////////////////
/// 
.react-flow {
  /* Custom Variables */
  --xy-theme-selected: #F57DBD;
  --xy-theme-hover: #C5C5C5;
  --xy-theme-edge-hover: black;
  --xy-theme-color-focus: #E8E8E8;

  /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
  --xy-node-border-default: 1px solid #EDEDED;

  --xy-node-boxshadow-default:
    0px 3.54px 4.55px 0px #00000005,
    0px 3.54px 4.55px 0px #0000000D,
    0px 0.51px 1.01px 0px #0000001A;

  --xy-node-border-radius-default: 8px;

  --xy-handle-background-color-default: #ffffff;
  --xy-handle-border-color-default: #AAAAAA;

  --xy-edge-label-color-default: #505050;
}

.react-flow.dark {
  --xy-node-boxshadow-default:
    0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05),
    /* light shadow */
    0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13),
    /* medium shadow */
    0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);
  /* smallest shadow */
  --xy-theme-color-focus: #535353;
}

/////////////////////////////////////////
/// 
/// 
.check_box {
  display: none;

  /* toggle in the OFF state */
  ~label {
    position: relative;
    display: block;
    width: 38px;
    min-width: 38px;
    max-width: 38px;
    height: 20px;
    border-radius: 40px;
    border: 2px solid #999;
    transition: transform 200ms cubic-bezier(0.41, -0.01, 0.63, 1.09);
    cursor: pointer;
    background: rgba(black, 0);

    &::before,
    &::after {
      position: absolute;
      top: 2px;
      left: 1.5px;
      width: 13px;
      height: 13px;
      border-radius: 36px;
      content: "";
      transition: all 220ms cubic-bezier(0.76, 0.01, 0.15, 0.97);
    }



    &::after {
      background-color: #999;
    }
  }

  /* toggle in the ON state */
  &:checked~label {
    border: 2px solid #fff;
    // transform: scale(1.01);
    box-shadow: 0px 0px 4px 1px rgb(96 83 241 / 65%);
    border-color: rgb(96 83 241 / 85%);


    &::before {
      width: 20px;
    }

    &::after {
      transform: translateX(19px);
      // transform: scale(1.01);
      background-color: rgb(76, 60, 255);
      box-shadow: -4px 0 4px rgba(black, 0.1);
    }
  }

  /* This is the part that activates the background when the checkbox is checked */
  &:checked~div {

    opacity: 1;
    visibility: visible;
    transition: opacity 240ms, visibility 0s;
    animation: rot 10s linear infinite;
  }
}

///////////////////////////////////
.vulnerability_box_item {

  button {
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    transition: all 0.2s;
  }

  button:hover {
    background-color: var(--bs-light);
    transform: translateY(-1px);
  }
}

.table> :not(:first-child) {
  border-top: none;
}

.skeleton {
  position: relative;
  overflow: hidden;
}

.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
  animation: skeleton-loading 1.5s infinite;
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}


@media (max-width: 991.98px) {
  .work_drawer {
    height: calc(100% - 60px) !important;
    top: 60px !important;
  }
}